// modalHandler.js

let manuallyOpened = false;

// Open modal
function openModal() {
    const modal = document.getElementById('demo-modal-popup');
    if (modal) {
        modal.style.display = 'block';
        modal.style.opacity = '0';
        setTimeout(() => {
            modal.style.opacity = '1';
        }, 10);

        manuallyOpened = true; // Ensure this flag is set when manually opened
    }
}

// Close modal
function closeModal() {
    const modal = document.getElementById('demo-modal-popup');
    if (modal) {
        modal.style.opacity = '0';
        setTimeout(() => {
            modal.style.display = 'none';
        }, 500);
    }
}

// Initialize modal listeners
function initModalListeners() {
    // Use a shared class for all modal triggers
    const modalTriggers = document.querySelectorAll('.demo-popup-trigger'); // Updated to use .demo-popup-trigger class
    const closeButtons = document.querySelectorAll('.demo-modal-close'); // Close buttons inside the modal

    // Open modal on button click
    if (modalTriggers) {
        modalTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                openModal();
            });
        });
    }

    // Close modal on close button click
    if (closeButtons) {
        closeButtons.forEach((btn) => {
            btn.addEventListener('click', () => {
                closeModal();
            });
        });
    }

    // Close modal when clicking outside the modal content
    const modal = document.getElementById('demo-modal-popup');
    if (modal) {
        modal.addEventListener('click', (event) => {
            const content = modal.querySelector('.demo-modal-content');
            if (event.target === modal && content && !content.contains(event.target)) {
                closeModal();
            }
        });
    }
}

// Show modal when user reaches `footer-cta` or is 200px from the bottom of the page
function checkScrollPosition() {
    if (manuallyOpened) return; // Skip if the modal has already been manually opened

    const footerCTA = document.querySelector('.footer-cta');
    const footerCTAOffset = footerCTA ? footerCTA.getBoundingClientRect().top : Infinity;
    const scrollThreshold = window.innerHeight + window.scrollY >= document.body.offsetHeight - 200;

    if (footerCTAOffset <= window.innerHeight || scrollThreshold) {
        openModal();
        window.removeEventListener('scroll', checkScrollPosition); // Remove scroll listener once executed
    }
}

// Exit intent detection (detect upward mouse movement)
function initExitIntent() {
    let modalShown = false;
    let lastMouseY = 0; // Track the last Y position of the mouse

    document.addEventListener('mousemove', (event) => {
        if (manuallyOpened || modalShown) return; // Skip if the modal has already been manually opened or shown

        const movingUp = event.clientY < lastMouseY; // Check if the mouse is moving up
        lastMouseY = event.clientY; // Update the last mouse Y position

        if (movingUp && event.clientY < 50) {
            modalShown = true;
            openModal();
        }
    });
}

// Initialize modal functionality
document.addEventListener('DOMContentLoaded', () => {
    initModalListeners();
    window.addEventListener('scroll', checkScrollPosition);
    initExitIntent();
});
