function initComparisons() {
    var overlays = document.getElementsByClassName("bg-replace-overlay");
    for (var i = 0; i < overlays.length; i++) {
        compareImages(overlays[i]);
    }

    function compareImages(img) {
        var slider, clicked = 0, w, h;

        // Get image dimensions
        w = img.offsetWidth;
        h = img.offsetHeight;

        // Set initial width to 50%
        img.style.width = (w / 2) + "px";

        // Create slider element
        slider = document.createElement("DIV");
        slider.setAttribute("class", "bg-replace-slider");
        img.parentElement.insertBefore(slider, img);

        // Position slider in the middle
        slider.style.top = (h / 2) - (slider.offsetHeight / 2) + "px";
        slider.style.left = (w / 2) - (slider.offsetWidth / 2) + "px";

        // Add event listeners
        slider.addEventListener("mousedown", slideReady);
        slider.addEventListener("touchstart", slideReady);
        window.addEventListener("mouseup", slideFinish);
        window.addEventListener("touchend", slideFinish);

        function slideReady(e) {
            e.preventDefault();
            clicked = 1;
            window.addEventListener("mousemove", slideMove);
            window.addEventListener("touchmove", slideMove);
        }

        function slideFinish() {
            clicked = 0;
            window.removeEventListener("mousemove", slideMove);
            window.removeEventListener("touchmove", slideMove);
        }

        function slideMove(e) {
            if (clicked === 0) return;
            var pos = getCursorPos(e);
            if (pos < 0) pos = 0;
            if (pos > w) pos = w;
            slide(pos);
        }

        function getCursorPos(e) {
            var rect = img.getBoundingClientRect();
            e = e.changedTouches ? e.changedTouches[0] : e;
            return e.pageX - rect.left - window.pageXOffset;
        }

        function slide(pos) {
            img.style.width = pos + "px";
            slider.style.left = img.offsetWidth - (slider.offsetWidth / 2) + "px";
        }
    }
}

// Initialize comparisons on DOM load
document.addEventListener("DOMContentLoaded", function () {
    initComparisons();
});
